import useMarketplaceChain from 'hooks/useMarketplaceChain'
import { Box, Flex, Text } from '../primitives'
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
  ComponentPropsWithoutRef,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import supportedChains from 'utils/chains'
import {
  useTrendingCollections,
  useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import { CollectionsSortingOption } from 'components/common/CollectionsTimeDropdown'
import { MintsSortingOption } from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import bg from '../../public/index/bgstarslarge.png'
import Image from 'next/image'
import Link from 'next/link'
// import Slider from 'react-slick'
// import nft_9 from '../../public/index/nft_9.png'
// import nft_2 from '../../public/index/nft_2.webp'
// import nft_3 from '../../public/index/nft_3.webp'
// import nft_4 from '../../public/index/nft_4.png'
// import nft_5 from '../../public/index/nft_5.webp'
// import nft_6 from '../../public/index/nft_6.webp'
// import nft_7 from '../../public/index/nft_7.webp'
// import nft_8 from '../../public/index/nft_8.webp'
// import nft_10 from '../../public/index/nft_10.webp'
// import nft_1 from '../../public/index/nft_1.webp'
import girl from '../../public/index/girl-with-phone-ce910564.webp'
import weg from "../../public/index/wedge-white-pink-30b46b1c.svg"
import chilb from "../../public/index/chibi-genesis-collections-a5ffabac.webp"
import card from "../../public/index/card-giant-dragon-astro-ea9dd7cc.webp"
import collection from "../../public/index/collection-baby-dragons-81b0b165.webp"

import digi from "../../public/index/digi-v2-afd2fd72.webp"
import dark from "../../public/index/dark-spirit-b7498ad3.webp"
import spirit from "../../public/index/spirit-391ccd0c.webp"

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
  const router = useRouter()
  const { routePrefix } = useMarketplaceChain()
  // not sure if there is a better way to fix this
  const { theme: nextTheme } = useTheme()
  const [theme, setTheme] = useState<string | null>(null)
  useEffect(() => {
    if (nextTheme) {
      setTheme(nextTheme)
    }
  }, [nextTheme])

  const isSSR = typeof window === 'undefined'
  const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

  const [tab, setTab] = useState<TabValue>('collections')
  const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

  const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')
  let [countCard, setCountCard] = useState(0)
  let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
    limit: 20,
    period: sortByPeriod,
    type: 'any',
  }

  const { chain, switchCurrentChain } = useContext(ChainContext)

  useEffect(() => {
    if (router.query.chain) {
      let chainIndex: number | undefined
      for (let i = 0; i < supportedChains.length; i++) {
        if (supportedChains[i].routePrefix == router.query.chain) {
          chainIndex = supportedChains[i].id
        }
      }
      if (chainIndex !== -1 && chainIndex) {
        switchCurrentChain(chainIndex)
      }
    }
  }, [router.query])

  const {
    data: trendingCollections,
    isValidating: isTrendingCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'volume',
      period: sortByTime,
    },
    chain.id,
    {
      fallbackData: '',
      keepPreviousData: true,
    }
  )
  const {
    data: featuredCollections,
    isValidating: isFeaturedCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'sales',
      period: '24h',
    },
    chain.id,
    {
      fallbackData: '',
      keepPreviousData: true,
    }
  )
  const { data: trendingMints, isValidating: isTrendingMintsValidating } =
    useTrendingMints({ ...mintsQuery }, chain.id, {
      fallbackData: '',
      keepPreviousData: true,
    })
  let volumeKey: ComponentPropsWithoutRef<
    typeof CollectionRankingsTable
  >['volumeKey'] = '1day'

  switch (sortByTime) {
    case '30d':
      volumeKey = '30day'
      break
    case '7d':
      volumeKey = '7day'
      break
    case '24h':
      volumeKey = '1day'
      break
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  }

  var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    className: 'center',
    centerMode: true,
    centerPadding: '60px',
  }

  return (
    <div style={{backgroundColor: "#fff"}}>
      <div className="_section_kj3qy_1">
      <div className="_bgGlow_kj3qy_53"></div>
      <div className="_imgContainer_kj3qy_17">
        <Image src={girl} alt="img"></Image>
      </div>
      <div className="_content_kj3qy_69">
        <h1 className="_title_kj3qy_82">Digi Universe</h1>
        <h2 className="_title_kj3qy_82">Join our community</h2>
        <p className="_description_kj3qy_97">
        Now come to join our community & search the digital world
        </p>
      </div>
      <div className="_socialLinks_kj3qy_110">
        <Link className='_socialLink_kj3qy_110' href={`/${routePrefix}/collections/trending`}>
          <span>EXPLORE</span>
          <div className='_pointerDown_kj3qy_172'></div>
        </Link>
        <Link className='_socialLink_kj3qy_110' href={`/${routePrefix}/explore`}>
          <span>COLLECTIONS</span>
          <div className='_pointerUp_kj3qy_190'></div>
        </Link>
      </div>
      <Image className="_wedge_kj3qy_210" src={weg} alt="img"></Image>
      </div>
     
      <div className='_collectionsContainer_juhx1_8'>
        <h1>Main Characters in Digi Universe</h1>
        <div className='_collectionGroup_juhx1_26'>
            {/* <h2>Core Collections</h2> */}
            <div>
              <Image src={chilb} alt='xxx'></Image>
              <span>Childish girls</span>
            </div>
            <div>
              <Image src={collection} alt='xxx'></Image>
              <span>Baby Dragons</span>
            </div>
            <div>
              <Image src={spirit} alt='xxx'></Image>
              <span>Girls (spirits)</span>
            </div>
        </div>
        <div className='_collectionGroup_juhx1_26'>
            {/* <h2>Core Collections</h2> */}
            <div>
              <Image src={digi} alt='xxx'></Image>
              <span>Core image - girls</span>
            </div>
            <div>
              <Image src={card} alt='xxx'></Image>
              <span>Dragons</span>
            </div>
            <div>
              <Image src={dark} alt='xxx'></Image>
              <span>Girls (dark spirits)</span>
            </div>
        </div>
      </div>

      {/* <canvas data-engine="three.js r161" width="1903" height="911"></canvas> */}
      {/* <div
        style={{
          width: '100%',
          height: '540px',
          background: `url(${zir9t.src}) center`,
          backgroundColor: '#000',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      ></div> */}
      {/* <div  style={{ height: `calc( 100vh - 440px )`, backgroundColor:"#2b303d",backgroundImage:"radial-gradient(hsla(0,0%,100%,.1) 1px,transparent 0)", backgroundSize:'40px 40px', backgroundPosition:"-19px -19px" }}>
         <Flex align="center" className='btnSty'  css={{
               flexDirection:"column",
               "@md":{
                   width:"40%",
                   height:"100%",
                   flexDirection:"row",
                   margin:"0 auto",
                  //  justifyContent:"space-around",
                  //  alignItems:"center"
               }
           }}>
               <Link href={`/${routePrefix}/collections/trending`} style={{
                   padding: "10px 20px",
                   margin: "40px",
                   fontSize: "1em",
                   fontFamily: "PX",
                   color: "#000000",
                   backgroundColor: "#ffffff",
                   borderRadius:"0.375rem",
                   cursor: "pointer",
                   outline: "none",
                   fontWeight:"600",
               }}>
                   <span style={{
                       color: "#000000",
                       fontSize: '20px',
                   }}>EXPLORE</span>
               </Link>
               <Link href={`/${routePrefix}/explore`} style={{
                   padding: "10px 20px",
                   marginRight: "10px",
                   fontSize: "1em",
                   fontFamily: "PX",
                   color: "#000000",
                   backgroundColor: "#ffffff",
                   borderRadius:"0.375rem",
                   cursor: "pointer",
                   outline: "none",
                   fontWeight:"600"
               }}>
                   <span style={{
                       color: "#000000",
                       fontSize: '20px',
                   }}>COLLECTIONS</span>
               </Link>
           </Flex>
      </div> */}
      {/* <div className='titleImg' >
        <div className='textSty' style={{
            position:"absolute",
            left:"100%",
            padding:"0.75rem",
            fontSize:'0.875rem',
            backgroundColor:"#ffffff",
            borderRadius:"0.25rem",
            color:"#000000",
        }}>Hey！Connect your wallet to begin your magic NFT trip.</div>
        <Image src={sdfasd} alt='xx'></Image>
      </div> */}
    </div>
  )
}

type TrendingCollectionsSchema =
  paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
  paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
  ssr: {
    trendingMints: TrendingMintsSchema
    trendingCollections: TrendingCollectionsSchema
    featuredCollections: TrendingCollectionsSchema
  }
}> = async ({ params, res }) => {
  const chainPrefix = params?.chain || ''
  const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
  // init BaseApi
  // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
  // DefaultChain
  // console.log("请求的路劲",reservoirBaseUrl)
  const headers: RequestInit = {
    headers: {
      'x-api-key': process.env.RESERVOIR_API_KEY || '',
    },
  }
  let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      sortBy: 'volume',
    }

  const trendingCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    trendingCollectionsQuery,
    headers
  )
  let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      sortBy: 'sales',
    }

  const featuredCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    featuredCollectionQuery,
    headers
  )

  let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      type: 'any',
    }

  const trendingMintsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending-mints/v1`,
    trendingMintsQuery,
    headers
  )

  const promises = await Promise.allSettled([
    trendingCollectionsPromise,
    featuredCollectionsPromise,
    trendingMintsPromise,
  ]).catch((e) => {
    console.error(e)
  })

  const trendingCollections: Props['ssr']['trendingCollections'] =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
      ? (promises[0].value.data as Props['ssr']['trendingCollections'])
      : {}
  const featuredCollections: Props['ssr']['featuredCollections'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
      ? (promises[1].value.data as Props['ssr']['featuredCollections'])
      : {}

  const trendingMints: Props['ssr']['trendingMints'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
      ? (promises[1].value.data as Props['ssr']['trendingMints'])
      : {}

  res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
  )
  return {
    props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
  }
}
export default NFTBanner
